import React, { Component } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  Nav,
  Navbar,
  NavItem,
  Collapse,
  NavLink as BSNavLink,  
} from "reactstrap";
import bn from "../../utility/bemnames";
import sidebarBgImage from "../../assets/img/sidebar/sidebar-4.jpg";
import companyLogo from "../../assets/img/logo/quattris-logo.svg";
import { MdDashboard,MdSettings, MdList, MdKeyboardArrowDown, MdPeople, MdSettingsSystemDaydream, MdAssignment, MdAssignmentInd, MdStorage, MdRowing, MdFeaturedPlayList, MdReport, MdSettingsApplications } from 'react-icons/md';
import SourceLink from '../component/SourceLink';
import GeneralService from '../../services/GeneralService';
import { bindActionCreators } from 'redux';
import { fetchPermission } from '../../redux/actions/permissionAction';
import { connect } from 'react-redux';
import AuthService from '../../services/AuthService';

interface IProps {
  permissions: any[];
  getPermission: any;
}

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};


class SideBar extends Component<IProps> {
  public state = {
    isOpenAdminPanel: false,
    isAdminAccess: false,    
    isOpenSettingPanel: false,
    isSettingAccess: false,
    permissions: []
  };

  handleClick = (name: string) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  async componentDidMount() {
    
    const isLoggedIn = AuthService.isUserLogin();
    if (isLoggedIn) {this.props.getPermission();}

    if (!this.isSidebarOpen()) {
      let currentRoute = window.location.pathname;
      if (currentRoute && currentRoute === "/login") {
        this.openSidebar("close");
      } else {
        this.openSidebar("open");
      }
    }
    
  }

  componentDidUpdate(prevProps: IProps) {
    // if (
    //   prevProps.permissions !== this.props.permissions &&
    //   this.props.permissions.length === 0
    // ) {
    //   this.props.getPermission()
    // }
  }
  

  isSidebarOpen() {
    let element = document.querySelector(".cr-sidebar");
    if (element) {
      return element.classList.contains("cr-sidebar--open");
    }
    return false;
  }

  openSidebar(openOrClose: any) {
    if (openOrClose === "open") {
      let element = document.querySelector(".cr-sidebar");
      if (element) {
        return element.classList.add("cr-sidebar--open");
      }
    }
    let element = document.querySelector(".cr-sidebar");
    if (element) {
      return element.classList.remove("cr-sidebar--open");
    }
  }  

  render() {
    const navItems = [
      { to: "/", name: "Dashboard", exact: true, Icon: MdDashboard },
      { to: "/Tickets", name: "Tickets", exact: true, Icon: MdAssignment },
      { to: "/Technician", name: "Technicians", exact: true, Icon: MdRowing },
      {
        to: "/Projects",
        name: "Projects",
        exact: true,
        Icon: MdFeaturedPlayList,
      },
      { to: "/Recruiters", name: "Partners", exact: true, Icon: MdPeople },
      // { to: "/Partners", name: "Partners", exact: true, Icon: MdPeople },
      { to: "/Reports", name: "Reports", exact: true, Icon: MdReport },
    ];

    const navAdminPanel = [
      { to: "/Users", name: "Users", exact: false, Icon: MdPeople },
      {
        to: "/LoginApprovals",
        name: "Login Approvals",
        exact: false,
        Icon: MdAssignmentInd,
      },
      { to: "/Roles", name: "Roles", exact: false, Icon: MdList },
      { to: "/Permissions", name: "Permissions", exact: false, Icon: MdList },
    ];

    const navSettingsPanel = [
      {
        to: "/SystemSettings",
        name: "System Settings",
        exact: false,
        Icon: MdSettingsSystemDaydream,
      },
      {
        to: "/GeneralSettings",
        name: "General Settings",
        exact: true,
        Icon: MdList,
      },
    ];

    const userHasPermission = (key: any) => {
      if (this.props.permissions && this.props.permissions.length > 0) {
        let hasPermission =
          this.props.permissions.find((f) => f == key) || undefined;
        if (typeof hasPermission !== "undefined" && hasPermission !== null) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    };

    const checkMenuPermission = (menuName: string): boolean => {
      switch (menuName) {
        case "Dashboard":
          return true;

        case "Tickets":
          return userHasPermission("ticket_access") ? true : false;

        case "Technicians":
          return userHasPermission("technicians_access") ? true : false;

        case "Projects":
          return userHasPermission("project_access") ? true : false;

        case "Recruiters":
          return userHasPermission("recruiter_access") ? true : false;

        case "Partners":
          return userHasPermission("partner_access") ? true : false;

        case "Users":
          return userHasPermission("users_access") ? true : false;

        case "Login Approvals":
          return userHasPermission("login_approval_access") ? true : false;

        case "Roles":
          return userHasPermission("roles_access") ? true : false;

        case "Permissions":
          return userHasPermission("permissions_access") ? true : false;
        
        case "Reports":
          return userHasPermission("reporting_access") ? true : false;

        case "System Settings":
          return userHasPermission("system_settings_access")
            ? true
            : false;

        case "General Settings":
          return userHasPermission("general_settings_access")
            ? true
            : false;

        default:
          return false;
      }
    };

    const bem = bn.create("sidebar");    
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar className="justify-content-center">
            <SourceLink className="navbar-brand">
              <img src={companyLogo} width="100%" height="30" alt="" />
            </SourceLink>
          </Navbar>
          {this.props.permissions.length === 0 ? (
            <></>
          ) : (
            <Nav vertical>
              {/* General List */}
              {navItems.map(({ to, name, exact, Icon }, index) =>
                checkMenuPermission(name) ? (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ) : (
                  <></>
                )
              )}

              {/* Admin Panel */}
              {userHasPermission("admin_access") ? (
                <>
                  <NavItem
                    className={bem.e("nav-item")}
                    onClick={this.handleClick("AdminPanel")}
                  >
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <MdStorage className={bem.e("nav-item-icon")} />
                        <span className="">Admin Panel</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenAdminPanel
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenAdminPanel}>
                    {navAdminPanel.map(({ to, name, exact, Icon }, index) =>
                      checkMenuPermission(name) ? (
                        <NavItem
                          key={index}
                          className={bem.e("nav-item sub-item")}
                        >
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                          >
                            <Icon className={bem.e("nav-item-icon")} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      ) : (
                        <></>
                      )
                    )}
                  </Collapse>
                </>
              ) : (
                <></>
              )}
              {/* Settings Panel */}
              {userHasPermission("setting_access") ? (
                <>
                  <NavItem
                    className={bem.e("nav-item")}
                    onClick={this.handleClick("SettingPanel")}
                  >
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <MdSettings className={bem.e("nav-item-icon")} />
                        <span className="">Settings</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenSettingPanel
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenSettingPanel}>
                    {navSettingsPanel.map(({ to, name, exact, Icon }, index) =>
                      checkMenuPermission(name) ? (
                        <NavItem
                          key={index}
                          className={bem.e("nav-item sub-item")}
                        >
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                          >
                            <Icon className={bem.e("nav-item-icon")} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      ) : (
                        <></>
                      )
                    )}
                  </Collapse>
                </>
              ) : (
                <></>
              )}

              <NavItem key={2232} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-theme-color-${2232}`}
                  tag={NavLink}
                  to={"/ThemeColor"}
                  activeClassName="active"
                  exact={true}
                >
                  <MdSettingsApplications className={bem.e("nav-item-icon")} />
                  <span className="">Theme Color</span>
                </BSNavLink>
              </NavItem>
            </Nav>
          )}
        </div>
      </aside>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getPermission: GeneralService.getPermissions,
      fetchPermissions: fetchPermission,
    },
    dispatch
  );

const mapStateToProps = (state: any) => ({
  permissions: state.permission.permissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);