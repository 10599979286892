import httpServices from "./Helper/HttpService"

const getRecruiter = async (): Promise<any> => {
    try {
        return await httpServices.get(`/sf_recruiter`)
    } catch (error) {
        throw error
    }
}

const getRecruiterDetail = async (id:any): Promise<any> => {
    try {
        let response:any = await httpServices.get(`/recruiter/${id}`)
        if (response && typeof response?.data === 'string') { return [] }
        return response
    } catch (error) {
        throw error
    }
}

const getPartners = async (status?: any, service?: any, country?: any): Promise<any> => {
    try {
        return await httpServices.get<any>(`/partner?status=${status}&service=${service}&country=${country}`)
    } catch (error) {
        throw error
    }
}

const getRecruiters = async (status?: any, service?: any, country?: any): Promise<any> => {
    try {
        return await httpServices.get(`/recruiter?status=${status}&service=${service}&country=${country}`)
    } catch (error) {
        throw error
    }
}

const createRecruiter = async (data: any): Promise<any> => {
    try {
        //console.log('coming here');
        //console.log(data);
        return await httpServices.postFormData('/partner', data)
        //return await httpServices.post('/recruiter', data)
    } catch (e) {
        throw e
    }
}

const updateRecruiter = async (id: any, data: any): Promise<any> => {
    try {

        //console.log('coming here2');
        //console.log(data);
        console.log(id);
        // debugger;
        return await httpServices.postFormData(`/partner/${id}`, data)
    } catch (e) {
        throw e
    }
}

const deletedRecruiter = async (id: any): Promise<any> => {
    try {
        return await httpServices.remove(`/partner/${id}`)
    } catch (e) {
        throw e
    }
}

const setArchive = async (id: any): Promise<any> => {
    try {
        return await httpServices.get(`/set-archive-partner/${id}`)
    } catch (e) {
        throw e
    }
}

const setBlackList = async (id: any): Promise<any> => {
    try {
        return await httpServices.get(`/set-blacklist-partner/${id}`)
    } catch (e) {
        throw e
    }
}

const restoreRecruiter = async (id: any): Promise<any> => {
    try {
        return await httpServices.get(`/restore-partner/${id}`)
    } catch (e) {
        throw e
    }
}

const getRecruiterTechnician = async (id:any): Promise<any> => {
    try {
        return await httpServices.get<any>(`/recruiter/technician/${id}`)
    } catch (e) {
        throw e
    }
}

const deletePartnerDocument = async (id: any): Promise<any> => {
    try {
        return await httpServices.get(`/delete-partner-docs/${id}`)
    } catch (e) {
        throw e
    }
}


export default {
    getRecruiter,
    getPartners,
    getRecruiters,
    createRecruiter,
    updateRecruiter,
    deletedRecruiter,
    setBlackList,
    restoreRecruiter,
    getRecruiterDetail,
    getRecruiterTechnician,
    deletePartnerDocument,
    setArchive
}