import moment from "moment"
import { fetchPermission } from "../redux/actions/permissionAction"
import httpServices from "./Helper/HttpService"

export interface ITicketStatus{
    value: string,
    label:string
}

export interface IDropDown {
    value: any;
    label: any;
}

const ticketStatus = [
    { value: 'new', label: 'New' },
    { value: 'withGTR', label: 'With GTR' },
    { value: 'awaitingClientConfirmation', label: 'Awaiting Client Confirmation' },
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'cancelledChargeable', label: 'Cancelled Chargeable' },
    { value: 'rescheduled', label: 'Rescheduled' },
    { value: 'rescheduledChargeable', label: 'Rescheduled Chargeable' },
    { value: 'onSite', label: 'On Site' },
    { value: 'completed', label: 'Completed' },
    { value: 'onHold', label: 'On Hold' },
    { value: 'revisitRequired', label: 'Revisit Required' },
    { value: 'billed', label: 'Billed' },
    { value: 'revisitRequiredChargeable', label: 'Revisit Required Chargeable' },
    { value: 'duplicate', label: 'Duplicate' },
]

const getPakZoneTime = async (country_id:any, city_id:any, time_country:any) => {
    try {
        return await httpServices.post("/pk-time", {
            country_id: country_id, city_id: city_id, time_country: time_country
        })
    } catch (error) {
        throw error
    }
}

export const capitalizeFirstLetter = (text?:string) => {
    return (text && text[0].toUpperCase() + text.slice(1)) || "";
}

export const getFirstLetter = (text?: string) => {
    return (text && text[0].toUpperCase()) || "";
}

export const getStatusLabelValue = (value: any) => {    
    let exist = ticketStatus.find(x => x.value === value)
    if (!exist) {
        exist = { value: 'new', label: 'New' }
    }
    return exist
}

export const getStatusLabelValueDashboard = (value: any) => {
    let exist = ticketStatus.find(x => x.value === value)    
    return exist
}

export const convertTime24to12 = (time: any) => {
    time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
};

export const convertTime12to24 = (hours: any, minutes: any, modifier: any) => {

    if (hours === "12") {
        hours = "00";
    }

    if (modifier === "PM") {
        hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
};

export const calculateTimeHours = (timeIn:any,timeOut:any) => {    
    if (timeIn === null || timeIn == "" || timeOut === null || timeOut == ""){return 0}
    
    let clockIn = timeIn.split(':')
    let clockOut = timeOut.split(':')

    if (parseInt(clockOut[0]) < parseInt(clockIn[0])) {
    var startDate = moment();
    startDate.set("hours", clockIn[0]);
    startDate.set("minutes", clockIn[1]);
    startDate.set("seconds", 0);
    startDate.set("milliseconds", 0);
    var endDate = moment();
    endDate.set("hours", clockOut[0]);
    endDate.set("minutes", clockOut[1]);
    endDate.set("seconds", 0);
    endDate.set("milliseconds", 0);
    // Calculate hours
    var a = parseInt(clockIn[0]);
    var b = parseInt(clockOut[0]);
    var a2 = parseInt(clockIn[1]);
    var b2 = parseInt(clockOut[1]);
    var sameday_remaining_hours = 24 - a;
    var nextday_hours = b;
    var total_hours = sameday_remaining_hours + nextday_hours;

    if (a2 > b2) {
      var newclockout = 60 + b2;
      
      let mins = newclockout - a2;
      
      total_hours = total_hours - 1;
      return `${total_hours}.${mins}`;
    } else {
      let mins = b2 - a2;
      return `${total_hours}.${mins}`;
    }
    //Else art
  }

    var startDate = moment();
    startDate.set('hours', clockIn[0])
    startDate.set('minutes', clockIn[1])
    startDate.set('seconds', 0)
    startDate.set('milliseconds', 0)

    var endDate = moment();
    endDate.set('hours', clockOut[0])
    endDate.set('minutes', clockOut[1])
    endDate.set('seconds', 0)
    endDate.set('milliseconds', 0)

    let seconds = endDate.diff(startDate, 'seconds');
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    return `${h}.${m}`

}

const getCurrencies =async ():Promise<any> => {
    try {
        return await httpServices.get<any>("/sf_currencies")
    } catch (e) {
        throw e
    }
}

const getPermissions = () => {
    try {        
        return async(dispatch: any) => {
            let resposne = await httpServices.get<any>("/get-permission").then((r) => {                                
                if (r?.data) {
                    return r['data']['permission'];
                } else {
                    return []
                }
                
            })            
            dispatch(fetchPermission(resposne))
            
         }
    } catch (e) {
        throw e
    }
}

export const setDateTime = (date: any, hour: number, mint: number) => {
    if (moment(date).isValid()) {        
        let setDate = moment(date).set('hour', hour).set('minute', mint);
        return setDate.format('YYYY-MM-DD HH:mm') || null;
    }
    return null

}

export const getTimeStamp = (date: any, hour: number, mint: number) => {
    if (moment(date).isValid()) {
        let setDate = moment(date).set('hour', hour).set('minute', mint);
        return setDate.toDate() || 0;
    }
    return null

}

export const getTicketStatus = async (permissions: any[]) => {    
    let status: ITicketStatus[] = []    
    // let permissions: any[] = []; // = await getPermissions()

    let ticketstatus_permission = [
        'ticket_tbc',
        'ticket_with_GTR',
        'ticket_awaiting_client',
        'ticket_confirmed',
        'ticket_canceled',
        'ticket_cancelled_chargeable',
        'ticket_rescheduled',
        'ticket_rescheduled_chargeable',
        'ticket_on_site',
        'ticket_completed',
        'ticket_on_hold',                
        'ticket_revisit_required',
        'ticket_revisit_required_chargeable',
        'ticket_billed_closed',
        'ticket_duplicate',
    ]
    
    for (const ticketStatus of ticketstatus_permission) {
        if (permissions && permissions.length > 0 && permissions?.includes(ticketStatus)) {
            switch (ticketStatus) {
                case "ticket_tbc":
                    status.push({ value: "new", label: "New" })
                    break;
                case "ticket_with_GTR":
                    status.push({ value: "withGTR", label: "With GTR" })
                    break;
                case "ticket_awaiting_client":
                    status.push({ value: "awaitingClientConfirmation", label: "Awaiting Client Confirmation" })
                    break;
                case "ticket_confirmed":
                    status.push({ value: "confirmed", label: "Confirmed" })
                    break;
                case "ticket_canceled":
                    status.push({ value: "canceled", label: "Canceled" })
                    break;
                case "ticket_cancelled_chargeable":
                    status.push({ value: "cancelledChargeable", label: "Cancelled Chargeable" })
                    break;
                case "ticket_rescheduled":
                    status.push({ value: "rescheduled", label: "Rescheduled" })
                    break;
                case "ticket_rescheduled_chargeable":
                    status.push({ value: "rescheduledChargeable", label: "Rescheduled Chargeable" })
                    break;
                case "ticket_on_site":
                    status.push({ value: "onSite", label: "On Site" })
                    break;
                case "ticket_completed":
                    status.push({ value: "completed", label: "Completed" })
                    break;
                case "ticket_on_hold":
                    status.push({ value: "onHold", label: "On Hold" })
                    break;                               
                case "ticket_revisit_required":
                    status.push({ value: "revisitRequired", label: "Revisit Required" })
                    break;
                case "ticket_revisit_required_chargeable":
                    status.push({ value: "revisitRequiredChargeable", label: "Revisit Required Chargeable" })
                    break;
                case "ticket_billed_closed":
                    status.push({ value: "billed", label: "Billed" })
                    break;
                case "ticket_duplicate":
                    status.push({ value: "duplicate", label: "Duplicate" })
                    break;
            }
            
        }
        
    }    
    return status
}

export const getServices = async () => {
    try {        
            let resposne = await httpServices.get<any>("/get-services").then((r) => {
                return r?.data?.services;
            })
        
        return resposne
         
    } catch (e) {
        throw e
    }
}

export const ModuleList = (): IDropDown[] => {
    return [
        { value: "dashboard", label: "Dashboard" },
        { value: "ticket", label: "Ticket" },
        { value: "technician", label: "Technician" },
        { value: "project", label: "Project" },
        { value: "recruiter", label: "Recruiter" },
        { value: "partner", label: "Partner" },
        { value: "reports", label: "Reports" },
        { value: "admin_panel", label: "Admin Panel" },
        { value: "settings", label: "Settings" }        
    ];
}

export const ModuleNameCapitalizeFirstLetter = (text?: string) => {
    let _name = text?.replace("_", " ")
    return (_name && _name[0].toUpperCase() + _name.slice(1)) || "";
}
 

export default {
    getPakZoneTime,
    getCurrencies,
    getPermissions
} 


