import classnames from 'classnames';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import Page from '../../component/Page';
import AddTechnicianContactDetails from './Sections/AddTechnicianContactDetails';
import AddTechnicianToolKit from './Sections/AddTechnicianToolKit';
import AddTechnicianOfficalStatus from './Sections/AddTechnicianOfficalStatus';
import AddTechnicianPersonalInfo from './Sections/AddTechnicianPersonalInfo';
import AddTechnicianOemCertificates from './Sections/AddTechnicianOemCertificates';
import AddTechnicianSkillSet from './Sections/AddTechnicianSkillSet';
import getToolKit from '../../../services/TechnicianService';
import TechnicianService from '../../../services/TechnicianService';
import CountryService from '../../../services/CountryService';
import CityService from '../../../services/CityService';
import LanguageService from '../../../services/LanguageService';
import AddTechnicianFeedback from './Sections/AddTechnicianFeedback';
import AddTechnicianLangauge from './Sections/AddTechnicianLanguage';
import { isThursday } from 'date-fns/esm';
import { threadId } from 'worker_threads';
import { thisExpression } from '@babel/types';
import { async } from 'q';
import { Toaster } from '../../component/Toaster';
import { ToasterType } from '../../component/Toaster';
import { match } from "react-router-dom";
import { IDocument } from '../../../models/IDocument';

interface IState {
  activeTab?: string;
  field_engineer_type?: any;
  technicians_id?: any;
  recruiter?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  whats_app?: string;
  skype?: string;
  profile_link?: string;
  employment_status?: string;
  availability?: string;
  country?: number;
  city?: number;
  address?: any;
  latitude?: number;
  longitude?: number;
  near_by_areas?: any;
  rate?: number;
  currency_rate: number;
  currencyId: any;
  feedback: string;
  can_contact: any;
  disability: any;
  disability_text: any;
  work_permit: string;
  language: any;
  lang: any;
  skillset: any;
  toolkit: any;
  certificate: any;
  cert_others: any;
  skill: any[];
  skill_level: any;
  filenames: any;
  permit: any;
  gender: any;
  dob: any;
  disablity: any;
  toolkitArray: any;
  countryArray: any;
  cityarray: any;
  invalidfirst_name: any;
  invalidlast_name: any;
  invalidgender: any;
  invaliddob: any;
  invalidcity: any;
  invalidemail: any;
  invalidcountry: any;
  toolkitEdit: any;
  invalidphone: any;
  invalidaddress: any;
  invalidwhats_app: any;
  invalidprofile_link: any;
  invalidskype: any;
  invalidemployment_status: any;
  invalidtitle: any;
  invalidrecruiter: any;
  invalidavailability: any;
  invalidcan_contact: any;
  invalidcurrency_rate: any;
  technician: any;
  technicianId: any;
  skillSetEdit: any;
  skillLevelEdit: any;
  cityId: any;
  editCertificateData: any;
  languageEdit: any;
  type?: any;
  work_permit_document?: any;
  token_user: boolean;
  languageFiles: any;
}

interface DetailParams {
  id?: string;
  token?: any;
}
interface DetailsProps {
  required: string;
  match: match<DetailParams>;
  history: { push(url: string): void };
}
export default class AddTechnician extends Component<DetailsProps, IState> {
  public state = {
    type: "direct",
    activeTab: "personalInfo",
    field_engineer_type: "",
    technicians_id: "",
    recruiter: "",
    cityId: "",
    skillSetEdit: [],
    skillLevelEdit: [],
    title: "",
    toolkitEdit: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whats_app: "",
    skype: "",
    profile_link: "",
    employment_status: "",
    availability: "",
    country: 1,
    city: 1,
    address: "",
    latitude: 1,
    longitude: 1,
    near_by_areas: [],
    rate: 1,
    currency_rate: 0,
    feedback: "",
    can_contact: "",
    disability: "",
    disability_text: "",
    work_permit: "",
    language: [],
    lang: [],
    skillset: [],
    toolkit: [],
    certificate: [],
    cert_others: "",
    skill: [],
    skill_level: "",
    filenames: [],
    permit: "",
    disablity: "",
    toolkitArray: [],
    countryArray: [],
    cityarray: [],
    gender: "",
    dob: "",
    invalidfirst_name: false,
    invalidlast_name: false,
    invalidgender: false,
    invaliddob: false,
    invalidcity: false,
    invalidemail: false,
    invalidcountry: false,
    invalidphone: false,
    invalidaddress: false,
    invalidwhats_app: false,
    invalidprofile_link: false,
    invalidskype: false,
    invalidemployment_status: false,
    invalidtitle: false,
    invalidrecruiter: false,
    invalidavailability: false,
    invalidcan_contact: false,
    invalidcurrency_rate: false,
    technician: "",
    technicianId: "",
    currencyId: null,
    languageEdit: [],
    editCertificateData: [],
    work_permit_document: [],
    token_user: false,
    languageFiles: [],
  };

  countryRef = React.createRef<any>();
  cityRef = React.createRef<any>();
  autocompleteRef = React.createRef<any>();
  Tabs = [
    "personalInfo",
    "contactDetails",
    "officialStatus",
    "skillSet",
    "oemCertificates",
    "toolkits",
    "languages",
    "feedback",
  ];

  getToolKitData = async () => {
    return await TechnicianService.getToolKit();
  };

  getCountryData = async () => {
    return await CountryService.getCountry();
  };

  getCityData = async (id: any) => {
    return await CityService.getCityByCountry(id);
  };

  public techId = this.props.match.params.id;

  getTechnicianbyId = async (id: any) => {
    let techBYID: any = await TechnicianService.getTechnicianbyId(this.techId);
    this.setState({
      technician: techBYID.data,
      type: techBYID.data?.type,
      first_name: techBYID.data?.name.split(" ")[0],
      last_name: techBYID.data?.name.split(" ")[1],
      disability: techBYID.data?.disability,
      disability_text: techBYID.data?.disability_text,
      email: techBYID.data?.email,
      skype: techBYID.data?.skype,
      profile_link: techBYID.data?.profile_link,
      whats_app: techBYID.data?.whats_app,
      phone: techBYID.data?.phone,
      country: techBYID.data?.country_id,
      city: techBYID.data?.city_id,
      address: techBYID.data?.address,
      employment_status: techBYID.data?.employment_status?.id,
      feedback: techBYID.data?.feedback,
      availability: techBYID.data?.availability,
      recruiter: techBYID.data?.recruiter?.id,
      can_contact: techBYID.data?.can_contact,
      title: techBYID.data?.title,
      latitude: parseFloat(techBYID.data?.latitude),
      longitude: parseFloat(techBYID.data?.longitude),
      certificate: techBYID.data?.certificate,
      toolkitEdit: techBYID.data?.toolkits,
      skillSetEdit: techBYID.data?.skillsets,
      skillLevelEdit: techBYID.data?.skills,
      rate: techBYID.data?.rate,
      currencyId: parseInt(techBYID.data?.currency_id),
      currency_rate: parseInt(techBYID.data?.currency_id),
      languageEdit: techBYID.data?.languages,
      editCertificateData: techBYID.data?.certificates,
      work_permit: techBYID?.data?.work_permit,
      near_by_areas: techBYID?.data?.near_by_areas?.map((n: any) => {
        return n?.id;
      }),
    });
    let countryData: any = await this.getCityData(techBYID.data?.country_id);
    if (countryData) {
      this.setState({
        cityarray: countryData.data.map((e: any) => {
          return {
            label: e.name,
            value: e.id,
          };
        }),
      });
    }

    if (techBYID?.data?.documents && techBYID?.data?.documents.length > 0) {
      let files: any[] = [];
      techBYID?.data?.documents?.filter((d: any) => {
        files.push({ id: d?.id, file: d?.url, name: d?.name });
      });
      this.setState({ filenames: files });
    }

    if (techBYID?.data?.languageDoc && techBYID?.data?.languageDoc.length > 0) {
      let files: any[] = [];
      techBYID?.data?.languageDoc?.filter((d: any) => {
        files.push({ id: d?.id, file: d?.url, name: d?.name });
      });
      this.setState({ languageFiles: files });
    }

    if (
      techBYID?.data?.work_permit_document &&
      techBYID?.data?.work_permit_document.length > 0
    ) {
      let files: any[] = [];
      techBYID?.data?.work_permit_document?.filter((d: any) => {
        files.push({ id: d?.id, file: d?.url, name: d?.name });
      });
      this.setState({ work_permit_document: files });
    }
  };

  async componentDidUpdate(prevProps: any) {
    if (prevProps.match.params.id !== (this.state.technicianId || undefined)) {
      this.setState({
        technicianId: this.techId,
      });
      await this.getTechnicianbyId(this.techId);
    }
  }

  async componentDidMount() {
    let token = this.props.match.params.token;
    if (typeof token !== "undefined" && token !== null && token !== "") {
      await TechnicianService.verifyShareFormToken(token).then((res: any) => {
        if (res?.data?.token === null) {
          this.props.history.push("/login");
        }
        this.setState({ token_user: true, email: res?.data?.email });
      });
    }

    let countryData: any = await this.getCountryData();
    if (countryData) {
      this.setState({
        countryArray: countryData.data?.map((e: any) => {
          return {
            label: e.name,
            value: e.id,
          };
        }),
      });
    }
  }

  handleNextSection = () => {
    for (let TabIndex = 0, len = this.Tabs.length; TabIndex < len; TabIndex++) {
      if (this.Tabs[TabIndex] === this.state.activeTab) {
        let next = this.Tabs[(TabIndex + len + 1) % len];
        if (this.state.activeTab === "personalInfo") {
          if (this.state.first_name === "") {
            this.setState({
              invalidfirst_name: true,
            });
          }
          if (this.state.last_name === "") {
            this.setState({
              invalidlast_name: true,
            });
          } else {
            this.setState({ activeTab: next });
          }
        }
        if (this.state.activeTab === "contactDetails") {
          if (this.state.email === "") {
            this.setState({
              invalidemail: true,
            });
          }
          // if (this.state.skype === "") {
          //   this.setState({
          //     invalidskype: true,
          //   });
          // }
          // if (this.state.profile_link === "") {
          //   this.setState({
          //     invalidprofile_link: true,
          //   });
          // }
          if (this.state.phone === "") {
            this.setState({
              invalidphone: true,
            });
          }
          if (this.state.country === 1) {
            this.setState({
              invalidcountry: true,
            });
          }
          if (this.state.city === 1) {
            this.setState({
              invalidcity: true,
            });
          }
          if (this.state.address === "") {
            this.setState({
              invalidaddress: true,
            });
          }
          if (this.state.whats_app === "") {
            this.setState({
              invalidwhats_app: true,
            });
          } else {
            this.setState({ activeTab: next });
          }
        }

        if (this.state.activeTab === "officialStatus") {
          if (this.state.availability === "") {
            this.setState({
              invalidavailability: true,
            });
          }
          if (this.state.can_contact === "") {
            this.setState({
              invalidcan_contact: true,
            });
          }
          if (this.state.employment_status === "") {
            this.setState({
              invalidemployment_status: true,
            });
          }
          if (this.state.title === "") {
            this.setState({
              invalidtitle: true,
            });
          }

          
          if (!this.state.currencyId && !this.state.token_user) {
            this.setState({
              invalidcurrency_rate: true,
            });
          } else {
            this.setState({ activeTab: next });
          }
        }
        if (
          this.state.activeTab === "skillSet" ||
          this.state.activeTab === "languages" ||
          this.state.activeTab === "oemCertificates" ||
          this.state.activeTab === "toolkits" ||
          this.state.activeTab === "feedback"
        ) {
          this.setState({ activeTab: next });
        }
      }
    }
  };

  handleBack = () => {
    for (let TabIndex = 0, len = this.Tabs.length; TabIndex < len; TabIndex++) {
      if (this.Tabs[TabIndex] === this.state.activeTab) {
        let next = this.Tabs[(TabIndex + len - 1) % len];
        this.setState({ activeTab: next });
      }
    }
  };

  handleAddress = (lat: any, lng: any, address: string) => {
    this.setState({
      latitude: lat,
      longitude: lng,
      address: address,
      invalidaddress: false,
    });
  };

  handleInput = (e: any) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      ["invalid" + e.target.name]: false,
    });
  };

  handleDate = (e: any, name: any) => {
    this.setState({
      ...this.state,
      [name]: e,
    });
  };

  handleInputFile = (file: any, name: string) => {
    let fileArrayUpdate: any = [];
    if (this.state.filenames.length === 0) {
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        filenames: fileArrayUpdate,
      });
    } else {
      fileArrayUpdate = [...this.state.filenames];
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        filenames: fileArrayUpdate,
      });
    }
  };

  handleLanguageFile = (file: any, name: string) => {
    let fileArrayUpdate: any = [];
    if (this.state.languageFiles.length === 0) {
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        languageFiles: fileArrayUpdate,
      });
    } else {
      fileArrayUpdate = [...this.state.languageFiles];
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        languageFiles: fileArrayUpdate,
      });
    }
  };

  handleInputWorkPermitFile = (file: any, name: string) => {
    let fileArrayUpdate: any = [];
    if (this.state.work_permit_document.length === 0) {
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        work_permit_document: fileArrayUpdate,
      });
    } else {
      fileArrayUpdate = [...this.state.work_permit_document];
      fileArrayUpdate.push({ id: 0, file: file, name: name });
      this.setState({
        work_permit_document: fileArrayUpdate,
      });
    }
  };

  handleDropdown = async (e: any, name: any) => {
    this.setState({
      ...this.state,
      [name]: e.value,
      ["invalid" + name]: false,
    });

    if (name === "currency_rate") {
      this.setState({
        currencyId: e.value,
      });
    }
    if (name === "country") {
      let countryData: any = await this.getCityData(e.value);
      if (countryData) {
        this.setState({
          cityarray: countryData.data.map((e: any) => {
            return {
              label: e.name,
              value: e.id,
            };
          }),
        });
      }
    }

    if (name === "nearby") {
      this.setState({
        near_by_areas: e?.map((c: any) => {
          return c?.value;
        }),
      });
    }
  };

  handleToolkit = (e: any) => {
    this.setState({
      toolkit: e,
    });
  };

  handleDelete = async (index: any) => {
    let deleteItem: IDocument = this.state.filenames[index];
    if (deleteItem && deleteItem?.id > 0) {
      await TechnicianService.deleteTechnicianDocument(deleteItem.id).then(
        (response: any) => {
          if (response?.data?.code === 200 || response?.data?.code === 400) {
            Toaster(
              "Techncian Document Deleted Successfuly",
              ToasterType.success
            );
            this.state.filenames.splice(index, 1);
            this.setState({ filenames: this.state.filenames });
          } else {
            Toaster("Techncian Document Deleted Failed", ToasterType.error);
          }
        }
      );
    } else {
      // delete from array
      this.state.filenames.splice(index, 1);
      this.setState({ filenames: this.state.filenames });
    }
  };

  handleLanguageFileDelete = async (index: any) => {
    let deleteItem: IDocument = this.state.languageFiles[index];
    if (deleteItem && deleteItem?.id > 0) {
      await TechnicianService.deleteTechnicianDocument(deleteItem.id).then(
        (response: any) => {
          if (response?.data?.code === 200 || response?.data?.code === 400) {
            Toaster(
              "Techncian Language Document Deleted Successfuly",
              ToasterType.success
            );
            this.state.languageFiles.splice(index, 1);
            this.setState({ languageFiles: this.state.languageFiles });
          } else {
            Toaster(
              "Techncian Language Document Deleted Failed",
              ToasterType.error
            );
          }
        }
      );
    } else {
      // delete from array
      this.state.languageFiles.splice(index, 1);
      this.setState({ languageFiles: this.state.languageFiles });
    }
  };

  handleWorkPermitFileDelete = async (index: any) => {
    let deleteItem: IDocument = this.state.work_permit_document[index];
    if (deleteItem && deleteItem?.id > 0) {
      // edit delete api call
      await TechnicianService.deleteTechnicianDocument(deleteItem.id).then(
        (response: any) => {
          if (response?.data?.code === 200) {
            Toaster(
              "Techncian Document Deleted Successfuly",
              ToasterType.success
            );
            this.state.work_permit_document.splice(index, 1);
            this.setState({
              work_permit_document: this.state.work_permit_document,
            });
          } else {
            Toaster("Techncian Document Deleted Failed", ToasterType.error);
          }
        }
      );
    } else {
      // add delete from array
      this.state.work_permit_document.splice(index, 1);
      this.setState({ work_permit_document: this.state.work_permit_document });
    }
  };

  handleCertifictate = (e: any) => {
    this.setState({
      certificate: e,
    });
  };

  handleTechncianSubmit = async () => {
    let data = new FormData();
    data.append("first_name", this.state.first_name);
    data.append("title", this.state.title);
    data.append("type", this.state.type);
    data.append("last_name", this.state.last_name);
    data.append("email", this.state.email);
    data.append("phone", this.state.phone);
    data.append("whats_app", this.state.whats_app);
    data.append("skype", this.state.skype);
    data.append("profile_link", this.state.profile_link);
    data.append("employment_status", this.state.employment_status);
    data.append("availability", this.state.availability);
    data.append("country", this.state.country.toString());
    data.append("city", this.state.city.toString());
    data.append("address", this.state.address);
    data.append("latitude", this.state.latitude.toString());
    data.append("longitude", this.state.longitude.toString());
    if (this.state.near_by_areas.length > 0) {
      this.state.near_by_areas?.filter((n) => {
        data.append("near_by_areas[]", n);
      });
    } else {
      data.append("near_by_areas[]", "");
    }

    data.append("rate", this.state.rate.toString());
    data.append("currency_rate", this.state.currency_rate.toString());
    data.append("feedback", this.state.feedback);
    data.append("can_contact", this.state.can_contact);
    let disability =
      this.state.disability === null && this.state.disability_text === null
        ? "false"
        : (this.state.disability === null ||
            typeof this.state.disability === "string") &&
          this.state.disability_text !== null
        ? "true"
        : this.state.disability;
    data.append("disability", disability);
    data.append("disability_text", this.state.disability_text);
    data.append("work_permit", this.state.work_permit);
    // data.append("permit", this.state.work_permit);
    data.append("recruiter", this.state.recruiter);
    data.append("language", JSON.stringify(this.state.language));
    this.state.skillset.length > 0
      ? this.state.skillset?.filter((s: any) => {
          data.append("skillset[]", s);
        })
      : data.append("skillset[]", "");

    this.state.certificate && this.state.certificate.length > 0
      ? this.state.certificate?.filter((c: any) => {
          data.append("certificate[]", c);
        })
      : data.append("certificate[]", "");
    data.append("skill", JSON.stringify(this.state.skill));
    data.append("cert_others", this.state.cert_others);
    this.state.toolkit.length > 0
      ? this.state.toolkit?.map((t: any) => {
          data.append("toolkit[]", t);
        })
      : data.append("toolkit[]", "");

    for (let index = 0; index < this.state.filenames.length; index++) {
      if (this.state.filenames[index]["id"] === 0) {
        data.append(`filenames[]`, this.state.filenames[index]["file"]);
        data.append(`docName[]`, this.state.filenames[index]["name"]);
      }
    }

    for (let index = 0; index < this.state.languageFiles.length; index++) {
      if (this.state.languageFiles[index]["id"] === 0) {
        data.append(
          `languageFilename[]`,
          this.state.languageFiles[index]["file"]
        );
        data.append(
          `languageDocName[]`,
          this.state.languageFiles[index]["name"]
        );
      }
    }

    for (
      let index = 0;
      index < this.state.work_permit_document.length;
      index++
    ) {
      if (this.state.work_permit_document[index]["id"] === 0) {
        data.append(
          `work_permit_file[]`,
          this.state.work_permit_document[index]["file"]
        );
        data.append(
          `permit_doc_name[]`,
          this.state.work_permit_document[index]["name"]
        );
      }
    }

    try {
      if (this.props.match.params.id && this.state.token_user === false) {
        data.append("_method", "PUT");
        await TechnicianService.updateTechnician(
          this.props.match.params.id,
          data
        ).then((response: any) => {
          if (response) {
            Toaster("Technichian Updated Succesfully", ToasterType.success);
            this.props.history.push("/Technician");
          } else {
            Toaster("Technichian Edit Failed", ToasterType.error);
          }
        });
      } else if (this.state.token_user === false) {
        await TechnicianService.createTechnician(data).then((response: any) => {
          if (response) {
            Toaster("Technichian Added Succesfully", ToasterType.success);
            this.props.history.push("/Technician");
          } else {
            Toaster("Technichian Added Failed", ToasterType.error);
          }
        });
      } else if (this.state.token_user === true) {
        data.append("token", this.props.match.params.token);
        await TechnicianService.createShareFormTechnician(data).then(
          (response: any) => {
            if (response?.message) {
              Toaster(response?.message, ToasterType.error);
            } else {
              Toaster("Technichian Added Succesfully", ToasterType.success);
              this.props.history.push("/login");
            }
          }
        );
      }
    } catch (err) {
      Toaster("Please fill in all the required fields", ToasterType.error);
    }
  };

  handleSkills = async (skills: any, skilllvl: any) => {
    let setSkills = skills.map((e: any) => {
      let findYear = skilllvl.find((s: any) => s.index === e?.id);
      return { id: e?.id, skill_level: `${findYear.year}` };
    });

    await this.setState({
      skill: setSkills,
    });
  };

  handleSkillSet = (e: any) => {
    this.setState({
      skillset: e,
    });
  };

  handleLangauges = (e: any) => {
    this.setState({
      language: e,
    });
  };

  handleProficiency = (e: any) => {
    // this.setState({
    //   language: e
    // })
  };

  render() {
    const toggle = (tab: any) => {
      if (this.state.activeTab !== tab) {
        this.setState({ activeTab: tab });
      }
    };
    return (
      <Page>
        <Row>
          <Col>
            <Card className="custCard">
              <CardBody>
                <Nav className="noClick" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "personalInfo",
                      })}
                      onClick={() => {
                        toggle("personalInfo");
                      }}
                    >
                      Personal Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "contactDetails",
                      })}
                      onClick={() => {
                        toggle("contactDetails");
                      }}
                    >
                      Contact Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "officialStatus",
                      })}
                      onClick={() => {
                        toggle("officialStatus");
                      }}
                    >
                      Official Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "skillSet",
                      })}
                      onClick={() => {
                        toggle("skillSet");
                      }}
                    >
                      Skill Set
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "oemCertificates",
                      })}
                      onClick={() => {
                        toggle("oemCertificates");
                      }}
                    >
                      OEM Certificates
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "toolkits",
                      })}
                      onClick={() => {
                        toggle("toolkits");
                      }}
                    >
                      Toolkits
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "languages",
                      })}
                      onClick={() => {
                        toggle("languages");
                      }}
                    >
                      Languages
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {this.state.token_user === false ? (
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "feedback",
                        })}
                        onClick={() => {
                          toggle("feedback");
                        }}
                      >
                        Feedback
                      </NavLink>
                    ) : (
                      <></>
                    )}
                  </NavItem>
                </Nav>
                <TabContent className="pt15" activeTab={this.state.activeTab}>
                  <TabPane tabId="personalInfo">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianPersonalInfo
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        disablity={this.state.disability}
                        technicianObj={this.state.technician}
                        inValidgender={this.state.invalidgender}
                        inValidDOB={this.state.invaliddob}
                        invalidFirstName={this.state.invalidfirst_name}
                        invalidLastName={this.state.invalidlast_name}
                        handleDate={this.handleDate}
                        handleDropdown={this.handleDropdown}
                        handleChange={this.handleInput}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="contactDetails">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianContactDetails
                        shareForm={this.state.token_user}
                        near_by_area={this.state.near_by_areas}
                        work_permit={this.state.work_permit}
                        email={this.state.email}
                        city={this.state.city}
                        skype={this.state.skype}
                        profile_link={this.state.profile_link}
                        whats_app={this.state.whats_app}
                        phone={this.state.phone}
                        country={this.state.country}
                        address={this.state.address}
                        latitude={this.state.latitude}
                        longitude={this.state.longitude}
                        invalidcity={this.state.invalidcity}
                        invalidemail={this.state.invalidemail}
                        invalidcountry={this.state.invalidcountry}
                        invalidphone={this.state.invalidphone}
                        invalidaddress={this.state.invalidaddress}
                        invalidwhats_app={this.state.invalidwhats_app}
                        invalidprofile_link={this.state.invalidprofile_link}
                        invalidskype={this.state.invalidskype}
                        handleAddress={this.handleAddress}
                        cityArray={this.state.cityarray}
                        countryArray={this.state.countryArray}
                        handleDropdown={this.handleDropdown}
                        cityRef={this.cityRef}
                        countryRef={this.countryRef}
                        handleChange={this.handleInput}
                        files={this.state.work_permit_document}
                        handleWorkPermitFile={this.handleInputWorkPermitFile}
                        handleWorkPermitDeleteFile={
                          this.handleWorkPermitFileDelete
                        }
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="officialStatus">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianOfficalStatus
                       shareForm={this.state.token_user}
                        recruiter={this.state.recruiter}
                        title={this.state.title}
                        type={this.state.type}
                        files={this.state.filenames}
                        rate={this.state.rate}
                        employment_status={this.state.employment_status}
                        availability={this.state.availability}
                        can_contact={this.state.can_contact}
                        handleDelete={this.handleDelete}
                        handleInputFile={this.handleInputFile}
                        handleDropdown={this.handleDropdown}
                        handleChange={this.handleInput}
                        currencyId={this.state.currencyId}
                        invalidemployment_status={
                          this.state.invalidemployment_status
                        }
                        invalidtitle={this.state.invalidtitle}
                        invalidrecruiter={this.state.invalidrecruiter}
                        invalidavailability={this.state.invalidavailability}
                        invalidcan_contact={this.state.invalidcan_contact}
                        invalidcurrency_rate={this.state.invalidcurrency_rate}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="skillSet">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianSkillSet
                        skillLevelEdit={this.state.skillLevelEdit}
                        skillSetEdit={this.state.skillSetEdit}
                        handleSkills={this.handleSkills}
                        handleSkillSet={this.handleSkillSet}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="oemCertificates">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianOemCertificates
                        editCertificateData={this.state.editCertificateData}
                        handleCertifictate={this.handleCertifictate}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="toolkits">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianToolKit
                        toolkitEdit={this.state.toolkitEdit}
                        handleToolkit={this.handleToolkit}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="languages">
                    <div className="scroll addEmployeeScroll">
                      <AddTechnicianLangauge
                        languageEdit={this.state.languageEdit}
                        handleProficiency={this.handleProficiency}
                        handleLangauges={this.handleLangauges}
                        handleInputFile={this.handleLanguageFile}
                        handleDelete={this.handleLanguageFileDelete}
                        files={this.state.languageFiles}
                      />
                    </div>
                  </TabPane>

                  <TabPane tabId="feedback">
                    <div className="scroll addEmployeeScroll">
                      {this.state.token_user === false ? (
                        <AddTechnicianFeedback
                          feedback={this.state.feedback}
                          handleChange={this.handleInput}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
              <CardFooter className="d-flex justify-content-between align-items-center">
                <div className="actionWrapper">
                  {this.state.token_user === false ? (
                    <Link to="/Technician">
                      <Button className="btn-size">Back</Button>
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="actionWrapper d-flex justify-content-between align-items-center">
                  <div className="addBtn">
                    <Button className="btn-size" onClick={this.handleBack}>
                      Previous
                    </Button>
                  </div>
                  <div className="addBtn  ml-2">
                    {(this.state.activeTab === "feedback" &&
                      this.state.token_user === false) ||
                    (this.state.activeTab === "languages" &&
                      this.state.token_user === true) ? (
                      <Button
                        className="btn-size"
                        onClick={this.handleTechncianSubmit}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        className="btn-size"
                        onClick={this.handleNextSection}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}
