import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FaRedoAlt } from "@react-icons/all-files/fa/FaRedoAlt";
import ProjectService from "../../../services/ProjectService";
import { Toaster, ToasterType } from "../Toaster";

interface IProps {
  modal: boolean;
  toggle: any;
  type: string;
  message: string;
  icon: any;
  handleDelete?: any;
  handleArchive?:any;
  handlePermanantDelete?:any
  isDelete?:any;
}

export default class ConfirmationPopup extends Component<IProps, {}> {
  
  render() {
    
    const handleDeleteFile = () => {
      this.props.toggle();
      this.props.handleDelete();
    };

    const handleArchiveFile = ()=>{
      if(message === "Are you sure you want to remove this Document?" ){
        this.props.toggle();
      }else{
      this.props.toggle();
      this.props.handleArchive();
      }
    }

    const handlePermanantDelete = ()=>{
      this.props.toggle();
      this.props.handlePermanantDelete();
    }
    const check = (m ?:any) => {
      {
        if (message === "Are you sure you want to retrieve this Partner?") {
          return <p>Yes</p>         
        }else if(message === "Are you sure you want to Blacklist/Delete this Partner?"){
          return <p>BlackList</p>  
        }else{
          return <p>Yes</p>
        }
        
        }
    }
    const check2 = (m ?:any) => {
      {
        if (message === "Are you sure you want to retrieve this Partner?" || message === "Are you sure you want to remove this Document?") {
          return <p>No</p>         
        }else if(message === "Are you sure you want to Blacklist/Delete this Partner?"){
          return <p>Delete</p>  
        }else{
          return <p>No</p>
        }
        
        }
    }

    const finalContent = (toggle ?:any)=>{
    
      return( 
        <>
          {this.props.isDelete === true ? 
          <>
          <Button className="btn-size mr-2" onClick={handlePermanantDelete}>Yes</Button>
          <Button className="btn-size" onClick={toggle}>No</Button>
          </> : 
          <div>
             <Button className="btn-size mr-2" onClick={handleDeleteFile}>
      {  check(this.props.message)}
          
        </Button>
        <Button className="btn-size" onClick={handleArchiveFile}>
        {  check2(this.props.message)}
        </Button>
          </div>
        }
           
            
        
       
        </>
        )

    }

    // const displayContent= (m ?:any) =>{
    //  if(this.props.isDelete) {
    //   return <p>true</p>
    //  }else{
    //   return <p>false</p>
    //  }
    
    // }
    const { modal, toggle, type, message, icon, handleDelete,  handleArchive, isDelete} = this.props;
    return (
      <div>
       { console.log(this.props.isDelete)}
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          className="confirmation-modal max-wd-30vw modal-dialog-centered"
        >
          {/* <ModalHeader>{`Delete ${type}`}</ModalHeader> */}
          <ModalBody className="text-center pb-2">
            {/* <p cl>X</p> */}
            <div className="text-right">
            <Button  onClick={toggle}>
            X
            </Button>
            {/* <p>ds</p> */}
            {/* {displayContent()} */}

            </div>
            <div className="iconBox mb-3">{icon}</div>
            <p>{message}</p>
          </ModalBody>
          <ModalFooter className="pt-2">
           {finalContent(toggle)}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
