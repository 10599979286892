import { IUser } from '../models/IUser';
import CookieService, { CookieKey } from './CookieService';
import httpServices, {baseURL, Method } from './Helper/HttpService';
import LocalStorageService, { Key } from './LocalStorageService';




interface ILoginSuccess {
    user?: IUser,
    access_token?: string;
    token_type?: string;
    refresh_token?: string;
    expires_in?: number;
    account_approvel?: any;
}

const redirectToLoginPage = () => {
    removeTokens();
    window.location.replace('/#/login')
};

// Save tokens in current tab and receive updated tokens to other tabs
function saveOAuthTokens(access: string, ref: string) {
    LocalStorageService.setItem(Key.access_token, access);
    
}

// save token expiry
function saveTokenExpiry(expiry: string) {    
    if (typeof expiry !== 'undefined' && expiry !== null && expiry !== "") {        
        let date = parseDate(expiry)        
        LocalStorageService.setItem(Key.token_expiry, date);
            
    }

}

function parseDate(date:any) {
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
        return parsed;
    }

    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

// payload for login request should be serialized as Form Data, that is why we do not use http.post here
const sendOAuthRequest = async (formData: FormData) => {
    try {                     
        const response = await window.fetch(`${baseURL}/auth/login`, {        
            method: Method.POST,
            mode: 'cors',                                 
            cache: 'no-cache',
            body: formData
        });
        
        if (!response.ok) {                        
            window.location.replace('/#/login');
        }
        const responseJson: ILoginSuccess = await response.json();
        LocalStorageService.setItem('user', JSON.stringify(responseJson['data']['user']))
        saveOAuthTokens(responseJson["data"].access_token, responseJson["data"].refresh_token);
        saveTokenExpiry(responseJson["data"].expiry_date)
        return responseJson["data"];
    } catch (e) {        
        throw e;
    }
};

const login = async (username: string, password: string): Promise<ILoginSuccess> => {    
    let cookie:any = CookieService.getItem(CookieKey.key)
    const formData = new FormData();
    if (cookie) {
    formData.append('secrete_cookie', cookie);
    } else {
        let newCookies = new Date().getTime();
        CookieService.setItem(CookieKey.key, newCookies)
        formData.append('secrete_cookie', newCookies.toString());
    }
    formData.append('email', username);
    formData.append('password', password);    
    
    const response = await sendOAuthRequest(formData);
    return response;
};
const updateTrashold =async (id:any,tval: any) => { 
    try {        
        const response = await httpServices.post(`/update-thrashhold`, {"threashVal":tval,"userId":id });
        return response;
       
    } catch (error) {
        throw error
    }
}
const removeTokens = () => {
    LocalStorageService.removeItem(Key.access_token);    
    LocalStorageService.removeItem(Key.token_expiry);    
    LocalStorageService.removeItem("user");
};

const isUserLogin = () => {    
    let token = LocalStorageService.getItem<string>(Key.access_token) as string
    if (token) { return true }
    return false
}

const refresh = (refreshToken: string): Promise<any> => {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    return sendOAuthRequest(formData)
        
};

const reAuthChecking = () => refresh(LocalStorageService.getItem<string>(Key.refresh_token) as string);

// Decrease time in accuracy factor for preventive incorrectly timers execute
// For example " ", "decreasing" timers in inactive tabs and e.t.c.
function computeWithAccuracyMap(value: number, accuracyMap: {}): number {    
    const keyArray = Object.keys(accuracyMap)
        .map(i => +i)
        .reverse();
    let resultFactorKey = keyArray[0];
    keyArray.forEach(el => {
        if (value <= el) {
            resultFactorKey = el;
        }
    });
    return value * accuracyMap[resultFactorKey];
}

const REFRESH_THRESHOLD_SECONDS = 240;
const accuracyFactorBySecond = {
    21600: 0.9,
    3600: 0.7,
    600: 0.5
};
let activeTimeout: any;

const setUpTokenRefresher = (): any => {    

    if(isShareForm()){
        return;
    }


    const expiry = LocalStorageService.getItem<string>(Key.token_expiry);    
    if (!expiry) {
        return redirectToLoginPage();
    }


    

    let payload;
    try {
        payload = parseInt(expiry);
    } catch (err) {
        return redirectToLoginPage;
    }
    
    const diff = (payload - Date.now()) / 1000;
    if (diff <= 0) {
        // Token has expired, we can't refresh it
        return redirectToLoginPage();
    }

    const correctedDiff = computeWithAccuracyMap(diff, accuracyFactorBySecond);
    if (correctedDiff <= REFRESH_THRESHOLD_SECONDS) {
    //     // refresh(refreshToken);        
    } else {
        clearTimeout(activeTimeout);
        activeTimeout = setTimeout(setUpTokenRefresher, (correctedDiff - REFRESH_THRESHOLD_SECONDS) * 1000);
    }
};

function bindAuthEvents() {    
    if(!isShareForm()){
        window.onblur = () => clearTimeout(activeTimeout);
        window.onfocus = setUpTokenRefresher;
    }
}

function isShareForm(){
    const paramenter = window.location.href.split('/');
    if (paramenter[4] === "shareForm") {
        return true
    }
    return false
}

setUpTokenRefresher();
bindAuthEvents();


const forgotPassword = async (email: any,appUrl:any) => {
    try {        
        const response = await httpServices.post(`/auth/forgot_password`, { "email": email, "forgot_password_base_url": appUrl });
        return response;
    } catch (e) {
        throw e;
    }
}




const resetPassword = async (email: any, password:any,confirm_password:any,token:any) => {
    try {        
        const response = await httpServices.post(`/auth/reset-password`, { "email": email, "token": token, "password": password, "password_confirmation": confirm_password });
        return response;
    } catch (e) {
        throw e;
    }
}

const AuthService = {
    reAuthChecking,
    redirectToLoginPage,
    login,
    isUserLogin,
    forgotPassword,
    resetPassword,
    isShareForm,
    updateTrashold
};

export default AuthService
