import Cookies from "universal-cookie";

export enum CookieKey{
    key = 'set_cookie'
    
} 
const cookie = new Cookies()

const getItem = <T>(key: string): T | undefined => {
    return cookie.get(key)
};

const removeItem = (key: string): void => {
    cookie.remove(key)
};

const setItem = (key: string, value: any): void => {
    cookie.set(key,value)
};
const setItemOptions = (key: string, value: any,maxage:any): void => {
    cookie.set(key,value,{ path: '/', maxAge: maxage })
};

const CookieService = {
    removeItem,
    getItem,
    setItem,
    setItemOptions
};

export default CookieService
